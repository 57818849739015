import { Typography, makeStyles, Button } from '@material-ui/core';
import { Steps, useAppState } from '../../state';

const useStyles = makeStyles(theme => ({
  customerBgContainer: {
    marginBottom: '1em',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: '160%',
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '20vh',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
    },
  },
}));

export default function PickOrderView() {
  const { setCurrentStep, findQuery, coverURL } = useAppState();
  const goClientHome = () => setCurrentStep(Steps.landingAccountPage);
  const classes = useStyles();

  return (
    <div className='centrado'>
        <div className={classes.customerBgContainer} style={{backgroundImage: `url(${coverURL})`}}></div>  
        <br />
        <br />
        <br />
        <Typography align="center" variant="subtitle1">Click here to see the status of: </Typography>
        
        <Typography align="center" variant="h6"><a href="#orderId" > Your order # {findQuery}</a></Typography>
        <br />
        <br />
        <br />
        <Button onClick={goClientHome} color="primary" variant="contained" > New Help Request  </Button>
    </div>
  );
}
