import React, { useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
  Switch,
  Tooltip
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppState, Steps } from "../../../state";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";

import Divider from "@material-ui/core/Divider";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import SmallCheckIcon from "../../../icons/SmallCheckIcon";
import InfoIconOutlined from "../../../icons/InfoIconOutlined";
import demoEmployees from "../../../data/demoEmployees.json";

interface VideoSelectionScreenProps {
  name: string;
  roomName: string;
  phone: string;
  setStep: (step: Steps) => void;
}

export default function VideoLobbyScreen({
  name,
  roomName,
  setStep,
  phone
}: VideoSelectionScreenProps) {
  const {
    isAgent,
    setCurrentStep,
    coverURL,
    hBoxRequest,
    hRequestId,
    suggestedDepartment
  } = useAppState();

  const useStyles = makeStyles((theme: Theme) => ({
    customerBgContainer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage: `url(${coverURL})`,
      backgroundSize: "cover",
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "20vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top"
      }
    },
    gutterBottom: {
      marginBottom: "1em"
    },
    marginTop: {
      marginTop: "1em"
    },
    deviceButton: {
      width: "100%",
      border: "2px solid #aaa",
      margin: "1em 0"
    },
    localPreviewContainer: {
      paddingRight: "2em",
      marginBottom: "2em",
      [theme.breakpoints.down("sm")]: {
        padding: "0 2.5em"
      }
    },
    joinButtons: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "100%",
        "& button": {
          margin: "0.5em 0"
        }
      }
    },
    mobileButtonBar: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        margin: "1.5em 0 1em"
      }
    },
    mobileButton: {
      padding: "0.8em 0",
      margin: 0,
      color: "#CCC"
    },
    toolTipContainer: {
      display: "flex",
      alignItems: "center",
      "& div": {
        display: "flex",
        alignItems: "center"
      },
      "& svg": {
        marginLeft: "0.3em"
      }
    }
  }));

  let hostServerURL = window.location.hostname;
  hostServerURL === "localhost"
    ? (hostServerURL = "http://localhost:8081")
    : (hostServerURL = "https://" + window.location.hostname);

  let phone_number = null;
  let demoNumber = "";
  let employeeName = "";
  let employeeImage = "";
  let serviceURL = "";
  function getEmployee() {
    console.log("demoEmployees", demoEmployees);
    let employees = demoEmployees.demoEmployees;
    for (let i = 0; i < employees.length; i++) {
      let employee = employees[i];
      if (
        employee.department.toLowerCase() ===
          suggestedDepartment.toLowerCase() &&
        employee.on_duty
      ) {
        console.log("Employee found", employee);
        employeeName = employee.name;
        employeeImage = employee.image;
        phone_number = employee.phone_number;
        break;
      }
    }
  }

  getEmployee();

  let params = new URLSearchParams(document.location.search);
  if (phone_number != null) {
    demoNumber = phone_number;
  } else {
    demoNumber = params.get("demo") as string;
  }
  let ClientIDUrl = params.get("ClientID");
  let LocationIDUrl = params.get("LocationID");
  let employeeMessage = "";

  async function generateToken(
    phone_number: string,
    name: string,
    help_text: string
  ) {
    if (help_text == null || help_text == "") {
      help_text = "No Details";
    }
    const response = await fetch(`${hostServerURL}/generate-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_number: phone_number,
        name: name,
        help_text: help_text
      })
    });
    const result = await response.json();
    let token = result.token;
    employeeMessage = `Video Help Request: ${hRequestId} ${hostServerURL}/room/${roomName}?agent=ca&video&ClientID=${ClientIDUrl}&LocationID=${LocationIDUrl}&token=${await token}`;
    serviceURL = `${hostServerURL}/sms-chatme?message=${encodeURIComponent(
      employeeMessage
    )}&surl=${encodeURIComponent(hostServerURL)}`;

    if (demoNumber) {
      console.log("Routing to: ", demoNumber);
      serviceURL = `${hostServerURL}/sms-chatme?message=${encodeURIComponent(
        employeeMessage
      )}&surl=${encodeURIComponent(hostServerURL)}&demoNumber=${demoNumber}`;
    }
  }

  const isAnAgent = isAgent();
  useEffect(() => {
    setStep(Steps.videoLobby);
    setCurrentStep(Steps.videoLobby);
    const videoNotify = async () => {
      try {
        await generateToken(phone, name, hBoxRequest);
        console.log("serviceURL", serviceURL);
        const response = await fetch(serviceURL, { method: "POST" });
        if (response.ok) {
          const data = await response.json();
          console.log("Response:", data);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.error(error);
      }
    };

    isAnAgent ? console.log("Hello Agent") : videoNotify();
  }, [serviceURL, roomName, isAnAgent, setStep, setCurrentStep]);

  const classes = useStyles();
  const {
    getToken,
    isFetching,
    isKrispEnabled,
    isKrispInstalled
  } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const cancelLobby = () => {
    setStep(Steps.selectContactChannel);
  };

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      chatConnect(token);
      videoConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <br />
        <br />
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Joining Video Call
          </Typography>
        </div>
        <br />
        <br />
      </Grid>
    );
  }

  return (
    <div className={"centrado"}>
      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              <ToggleVideoButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              {/* <SettingsMenu mobileButtonClass={classes.mobileButton} /> */}
            </Hidden>
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ alignItems: "normal" }}
          >
            <div>
              <Hidden smDown>
                <ToggleAudioButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
                {/* <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} /> */}
              </Hidden>
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          {isKrispInstalled && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "1em" }}
            >
              <div className={classes.toolTipContainer}>
                <Typography variant="subtitle2">Noise Cancellation</Typography>
                <Tooltip
                  title="Suppress background noise from your microphone"
                  interactive
                  leaveDelay={250}
                  leaveTouchDelay={15000}
                  enterTouchDelay={0}
                >
                  <div>
                    <InfoIconOutlined />
                  </div>
                </Tooltip>
              </div>

              <FormControlLabel
                control={
                  <Switch
                    checked={!!isKrispEnabled}
                    checkedIcon={<SmallCheckIcon />}
                    disableRipple={true}
                    /* onClick={toggleKrisp} */
                  />
                }
                label={isKrispEnabled ? "Enabled" : "Disabled"}
                style={{ marginRight: 0 }}
                // Prevents <Switch /> from being temporarily enabled (and then quickly disabled) in unsupported browsers after
                // isAcquiringLocalTracks becomes false:
                disabled={isKrispEnabled && isAcquiringLocalTracks}
              />
            </Grid>
          )}
          <Divider />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: "1em" }}
          >
            <Hidden smDown>
              <Grid item md={7} sm={12} xs={12}>
                <SettingsMenu mobileButtonClass={classes.mobileButton} />
              </Grid>
            </Hidden>
            <img
              src={
                employeeImage
                  ? employeeImage
                  : "../../../images/Generic-Profile-image.png"
              }
              style={{ width: "100px", height: "100px", alignSelf: "center" }}
            ></img>
            <Grid item md={5} sm={12} xs={12}>
              <Typography>
                {" "}
                {suggestedDepartment && employeeName
                  ? `${employeeName} from the ${suggestedDepartment} department will be with you soon!`
                  : `An associate will be with you soon!`}{" "}
              </Typography>
              <Typography> Please join the video call.</Typography>
              <br />
              <div className={classes.joinButtons}>
                <Button
                  variant="contained"
                  color="primary"
                  data-cy-join-now
                  onClick={handleJoin}
                  disabled={disableButtons}
                >
                  Join Video Call
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={cancelLobby}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
