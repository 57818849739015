import React from 'react';

import { Button, TextField } from '@material-ui/core';
import { ChatBubbleOutline, PhoneAndroidOutlined, VideoCallOutlined } from '@material-ui/icons';
import { useAppState } from '../../state';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import useChatContext from '../../hooks/useChatContext/useChatContext';

export interface ContactProps {
    name: string;
    email: string;
    phone: string;
    roomName: string;
    setStep: Function;
    gotoChat: Function;
    gotoCall: Function;
    gotoVideo: Function;
}

export default function ContactChannelScreen(props: ContactProps) {
    const {coverURL, setHBoxRequest} = useAppState();
    const { setIsChatWindowOpen } = useChatContext();
    const goHome = () => { 
        const DCXCLIENT = localStorage.getItem("DCXCLIENT");
        if (DCXCLIENT !== null) {
          const serviceURL = JSON.parse(DCXCLIENT).serviceURL;
          window.location.assign(`/${serviceURL}`);
        } else {
          console.error("DCXCLIENT Service URL not found in localStorage");
        }
      }
      
    const useStyles = makeStyles((theme: Theme) => ({
        textSide: {
            color: 'white',
            padding: '2em 1em',
            fontWeight: 'normal',
            fontSize: '1.5em',
            textAlign: 'left',
        },
        channelBtn: {
            fontSize: '12px',
            padding: '5px',
            /* margin: '5px', */
            background: 'whitesmoke',
            border: '2px solid #808080',
            '& .MuiButton-label': {
                display: 'block',
                color: '#000',
                '& .MuiButton-startIcon': {
                    margin: '0',
                    color: '#000',
                    '& .MuiSvgIcon-root': {
                        fontSize: '42px',

                    }
                }
            }
        },
        customerBgContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${coverURL})`,
            backgroundSize: 'cover',
            width: '300px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
            },
        },
        searchInput:{
            '& label':{ fontSize: '1em'} 
        },

    }));  const classes = useStyles();

    const handleButtonChat = () => {
        props.gotoChat();
        setIsChatWindowOpen(true);
    };

    const handleButtonCallme = () => {
        props.gotoCall();
    }

    const handleButtonVideoCall = () => {
        props.gotoVideo();
        setIsChatWindowOpen(false);
    }

    const handleHBoxReq = (e: { target: any}) => { 
        setHBoxRequest(e.target.value) 
    }

    return (
        <div className='centrado'>
            
            <div style={{ display: 'flex', justifyContent: 'space-around', background: '#F29849' }}>
                <div className={classes.customerBgContainer}></div>
                <p className={classes.textSide}>Ok let me find you the right person.</p>
            </div>

            <br />
            <div className='centrado'>
                <TextField id="help-with" label="What do you want help with ?" variant="outlined" onChange={handleHBoxReq} fullWidth className={classes.searchInput}/>
            </div>

            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '12px', }}>
                <Button className={classes.channelBtn} size="large" variant="contained" startIcon={<ChatBubbleOutline />} onClick={() => handleButtonChat()}>Let's Chat</Button>
                <Button className={classes.channelBtn} size="large" variant="contained" startIcon={<PhoneAndroidOutlined />} onClick={() => handleButtonCallme()}>Phone Me</Button>
                <Button className={classes.channelBtn} size="large" variant="contained" startIcon={<VideoCallOutlined />} onClick={() => handleButtonVideoCall()}>Video Call</Button>
            </div>

            <br />
            <Button onClick={goHome} color="primary" variant="outlined" autoFocus> New Help Request </Button>

        </div>
    );

}
