import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ChatInput from './ChatInput/ChatInput';
import clsx from 'clsx';
import MessageList from './MessageList/MessageList';
import useDCXChatContext from '../../hooks/useChatContext/useDCXChatContext';
import MobileTopMenuBar from '../MobileTopMenuBar/MobileTopMenuBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      background: '#FFFFFF',
      zIndex: 9,
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid #E4E7E9',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
      },
    },
    hide: {
      display: 'none',
    },
    logoContainer: {
      //position: 'absolute',
      top: '0.7%',
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        //display: 'flex',
        alignItems: 'center',
        zIndex: 200,
        //textAlign: 'initial',
        '& svg': {
          height: '40px',
        },
      },
    },
    customerLogo: {
      maxHeight:'50px',
      marginBottom: '-20px',
    },
  })
);

export default function DCXChatWindow() {
  const classes = useStyles();
  const { isChatWindowOpen, messages, conversation } = useDCXChatContext();
  
  return (
    <div className={clsx(classes.chatWindowContainer)}>
      <div className={classes.logoContainer}>
       <MobileTopMenuBar />
      </div>
      <MessageList messages={messages} />
      <ChatInput conversation={conversation!} isChatWindowOpen={isChatWindowOpen} />
    </div>
  );
}
