import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme
} from "@material-ui/core";
import { useAppState, Steps } from "../../../state";
import {
  ClientEmployeesFetch,
  defaultClientData,
  ClientCoverImage,
  ClientLogoImage
} from "../../../data/ClientData";
import { get } from "http";

const url = new URL(window.location.href);
const urlClientID = url.searchParams.get("ClientID") as string;
const urlLocationID = url.searchParams.get("LocationID") as string;
const clientCover = ClientCoverImage(urlClientID);
const clientLogo = ClientLogoImage(urlClientID);
const clientData = defaultClientData();

const useStyles = makeStyles((theme: Theme) => ({
  customerBgContainer: {
    marginBottom: "1em",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${clientCover})`,
    backgroundSize: "160%",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "20vh",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top"
    }
  },
  gutterBottom: {
    marginBottom: "0.5em"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2em 0",
    "& div:not(:last-child)": {
      marginRight: "1em"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "2em",
      display: "block"
    }
  },
  textFieldContainer: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1.2em"
    }
  },
  subtitle1: {
    textAlign: "right"
  },
  roomTitleContainer: {
    marginBottom: "1em"
  },
  inroomname: {
    border: "none"
  },
  continueButton: {
    color: "white",
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

export interface RoomNameScreenProps {
  name: string;
  email: string;
  phone: string;
  roomName: string;
  setStep: (step: Steps) => void;
  setName: (name: string) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
  name,
  email,
  phone,
  roomName,
  setName,
  setEmail,
  setPhone,
  handleSubmit,
  setStep
}: RoomNameScreenProps) {
  const classes = useStyles();
  const {
    setVisitorName,
    setCustomerPhone,
    isAgent,
    isVideoCall,
    setCurrentStep,
    hRequestId,
    hBoxRequest,
    visitorName
  } = useAppState();
  const [tokenData, setTokenData] = useState({ cusName: "", requestText: "" });

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setVisitorName(event.target.value);
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = event.target.value;
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    const formattedPhoneNumber = cleanedPhoneNumber.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      "$1-$2-$3"
    );
    const limitedPhoneNumber = formattedPhoneNumber.slice(0, 12);
    setPhone(limitedPhoneNumber);
    setCustomerPhone(limitedPhoneNumber);
  };

  const agentSkip = () => {
    if (isVideoCall()) {
      setName("Agent/Video");
      setStep(Steps.videoLobby);
      setCurrentStep(Steps.videoLobby);
    } else {
      setName("Agent/Chat");
      setStep(Steps.chatLobby);
      setCurrentStep(Steps.chatLobby);
    }
  };

  const agentForwardHD = async () => {
    let clientData = await ClientEmployeesFetch(urlClientID, urlLocationID);
    console.log(await clientData);
    alert("Customer service request forwarded to Help Desk");
    // window.close();
  };

  const agentForwardManager = async () => {
    let clientData = await ClientEmployeesFetch(urlClientID, urlLocationID);
    console.log(await clientData);
    alert("Customer service request forwarded to Manager");
    // window.close();
  };

  let params = new URLSearchParams(document.location.search);
  let token = params.get("token");
  console.log("Token:", token);

  let hostServerURL = window.location.hostname;
  hostServerURL === "localhost"
    ? (hostServerURL = "http://localhost:8081")
    : (hostServerURL = "https://" + window.location.hostname);

  useEffect(() => {
    async function getTokenData() {
      const response = await fetch(
        hostServerURL + `/get-token?token=${token}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" }
        }
      );
      const res = await response.json();
      setTokenData({ cusName: res.Data.name, requestText: res.Data.help_text });
    }

    getTokenData();
  }, []);

  return (
    <>
      <div className={classes.customerBgContainer}></div>
      <br />
      {!isAgent() && (
        <>
          <Typography
            variant="h6"
            className={classes.gutterBottom + ", centrado"}
          >
            {" "}
            {clientData.textTitle}
          </Typography>

          <form onSubmit={handleSubmit}>
            <div className={classes.inputContainer}>
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                  First Name*
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>

              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-phone">
                  Phone Number*
                </InputLabel>
                <TextField
                  id="input-phone"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>

              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-email">
                  Email
                </InputLabel>
                <TextField
                  fullWidth
                  id="input-email"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
            </div>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!name || !roomName || !phone}
                className={classes.continueButton}
              >
                {" "}
                TALK TO US{" "}
              </Button>
            </Grid>
          </form>
        </>
      )}

      {isAgent() && (
        <>
          <Typography
            variant="subtitle1"
            className={classes.gutterBottom + ", centrado"}
          >
            {" "}
            Customer request: {hRequestId}
            {tokenData.requestText}
          </Typography>
          <br />
          <Button variant="contained" color="primary" onClick={agentSkip}>
            {isVideoCall()
              ? `VIDEO CALL WITH ${tokenData.cusName}`
              : `CHAT WITH ${tokenData.cusName}`}
          </Button>
          <br />
          <br />
          <Button
            variant="outlined"
            style={{ color: "#4caf50" }}
            onClick={agentForwardHD}
          >
            {" "}
            SEND TO HELP DESK{" "}
          </Button>
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            onClick={agentForwardManager}
          >
            {" "}
            SEND TO MANAGER{" "}
          </Button>
          <br />
        </>
      )}
    </>
  );
}
