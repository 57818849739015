import { SVGProps } from 'react';

export default function DCXLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg id="svg-dcxlogo"
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="100"
      viewBox="12.065 -19.214 957.848 269.214"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(15.64 5)"
      >
        <g transform="matrix(.98335 0 0 .9331 14.707 10.573)">
          <g
            fill="#FFB142"
            fillRule="nonzero"
            transform="translate(777.739 49.651)"
          >
            <path d="M118.241 37.78l-43.04 36.89h9.991l36.765-31.526 21.83 31.524h7.883L126.9 38.906 171.535.631h-9.951l-38.4 32.912L100.399.65H92.53l25.711 37.13z"></path>
            <path d="M19.47 68.2c-8.667 0-13.877-6.512-11.926-14.908l3.541-15.242 3.393-14.601C16.557 14.5 25.928 7.094 35.166 7.094h44.788l1.46-6.45H35.167c-12.261 0-24.254 9.48-27.013 21.354l-3.392 14.6-3.542 15.243C-1.67 64.278 6.63 74.65 19.47 74.65h44.768l1.486-6.45H19.47z"></path>
          </g>
          <g
            fill="#676767"
            fillRule="nonzero"
            transform="translate(226.89 50.282)"
          >
            <path d="M434.368 53.596h90.476l-2.241 10H432.06l-9.498 41.154h92.757l-2.308 10H410L436.492 0h102.97l-2.268 10h-92.766l-10.06 43.596z"></path>
            <path d="M339.88319 101.262337L316.258484 0 306 0 332.744618 114.763941 342.681996 114.763941 422.427418 0 410.201649 0z"></path>
            <path d="M290.773438 0L280.519104 0 254 114.78894 264.281494 114.78894z"></path>
            <path d="M162 10h74.773c6.961 0 11.002 5.575 9.358 12.697l-3.752 16.25c-1.84 7.97-10.031 14.625-17.9 14.625H170.9s-1.639 4.01-1.823 4.695c-.403 1.499-.6 3.047-.448 4.644.034.351.084.7.153 1.047.437 2.177 37.507 50.7 37.58 50.793h12.606L180 63.572h44.48c12.568 0 24.766-9.91 27.642-22.376l3.752-16.25C258.948 11.636 250.247 0 236.774 0H154l-26.366 114.751h10.258L162 10z"></path>
            <path d="M7 104.75l4.872 6.125L36.055 6.125 31.183 10h78.75c6.962 0 11.002 5.253 9.357 12.375l-15.64 67.75c-1.84 7.97-10.033 14.625-17.9 14.625H7zm106.393-12.375l15.64-67.75C132.108 11.313 123.408 0 109.934 0H27.206l-.895 3.875-24.183 104.75-1.414 6.125H85.75c12.567 0 24.765-9.91 27.643-22.375z"></path>
          </g>
          <g
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="miter"
            strokeWidth="10"
          >
            <path
              fill="#676767"
              stroke="#676767"
              d="M106.76 117.699L203.349 173.434 106.76 229.17z"
            ></path>
            <path
              fill="#62AAD8"
              stroke="#62AAD8"
              d="M1 173.435L97.589 117.7 97.589 229.17z"
            ></path>
            <path
              fill="#3370A5"
              stroke="#3370A5"
              d="M-3 165.498L-3 54.027 93.589 109.762z"
            ></path>
            <path
              fill="#F2A344"
              stroke="#F2A344"
              d="M2 45.735L98.589 -10 98.589 101.47z"
            ></path>
            <path
              fill="#F2E206"
              stroke="#F2E206"
              d="M118.76 -20.001L215.349 35.734 118.76 91.47z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

