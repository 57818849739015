import { styled } from '@material-ui/core/styles';
import useHeight from '../../hooks/useHeight/useHeight';
import IntroContainer from '../IntroContainer/IntroContainer';
export default function LandingScreen() {

  const Container = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr auto',
  });

  const height = useHeight();
  return (<>
    <Container style={{ height }}>
    <IntroContainer logoURL={""} clientName={""} >
        <div className='centrado'>
          Generic Landing
          <hr />
          * * *
          <hr />  
          Check: ClientID | LocationID
        </div>
      </IntroContainer>
    </Container>
  </>);
}
