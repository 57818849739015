import { useEffect, useState, ChangeEvent } from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Button,
  Theme
} from "@material-ui/core";
import { useAppState, Steps } from "../../state";

const useStyles = makeStyles((theme: Theme) => ({
  landingOptions: {
    textAlign: "left",
    padding: "1em",
    fontWeight: "bold",
    "& .MuiFormLabel-root": { fontSize: "1.1em" }
  },
  landingOptionsLabel: {
    display: "block",
    padding: "0px",
    marginBottom: "6px"
  },
  hidden: {
    display: "none"
  },
  searchInput: {
    //width: '100%',
  },
  customerBgContainer: {
    marginBottom: "1em",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "160%",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "20vh",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top"
    }
  }
}));

export default function HelpDeskView() {
  const {
    clientID,
    locationID,
    dcxClientName,
    setCurrentStep,
    setFindQuery,
    coverURL
  } = useAppState();
  const [findItem, setFindItem] = useState("");
  const [pickItem, setPickItem] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const now = new Date();
  const epochTime = Math.floor(now.getTime() / 1000);
  const [selectedOption, setSelectedOption] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (findItem.length >= 3 || pickItem.length >= 3) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [findItem, pickItem]);

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
    setButtonDisabled(true);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputId = event.target.id;
    const inputValue = event.target.value;

    if (inputId === "find-item") setFindItem(inputValue);
    if (inputId === "pickup-phone") setPickItem(inputValue);
  };

  const takeAction = () => {
    const findItemAction = () => {
      setFindQuery(findItem);
      setCurrentStep(Steps.findItemView);
    };
    const pickupOrderAction = () => {
      setFindQuery(pickItem);
      setCurrentStep(Steps.pickOrderView);
    };
    const getRoomAction = () => {
      const ClientRoom = dcxClientName?.toLowerCase().replace(/ /g, "_");
      const userRoomName = `/room/${ClientRoom}:${epochTime}`;
      !(clientID && locationID)
        ? alert("Please provide a LocationID")
        : (window.location.href = `${userRoomName}/?ClientID=${clientID}&LocationID=${locationID}`);
    };
    if (selectedOption === "find") findItemAction();
    if (selectedOption === "pickup") pickupOrderAction();
    if (selectedOption === "speak") getRoomAction();
  };

  return (
    <div className="centrado">
      <div
        className={classes.customerBgContainer}
        style={{ backgroundImage: `url(${coverURL})` }}
      ></div>
      <Typography variant="h5">
        Welcome to <br /> {dcxClientName}
      </Typography>
      <Typography variant="h6" style={{ padding: `.6em .3em` }}>
        What can I help you with?
      </Typography>
      <div className={classes.landingOptions}>
        <label className={classes.landingOptionsLabel}>
          <input
            type="radio"
            name="helpTasks"
            value="find"
            checked={selectedOption === "find"}
            onChange={handleOptionChange}
          />{" "}
          Find an item{" "}
        </label>
        <div className={selectedOption === "find" ? "" : classes.hidden}>
          <TextField
            id="find-item"
            label="What item ?"
            variant="outlined"
            onChange={handleInputChange}
            fullWidth
            className={classes.searchInput}
          />
        </div>

        <br />

        <label className={classes.landingOptionsLabel}>
          <input
            type="radio"
            name="helpTasks"
            value="pickup"
            checked={selectedOption === "pickup"}
            onChange={handleOptionChange}
          />{" "}
          Pickup order{" "}
        </label>
        <div className={selectedOption === "pickup" ? "" : classes.hidden}>
          <TextField
            id="pickup-phone"
            label="Phone #"
            variant="outlined"
            type="tel"
            onChange={handleInputChange}
          />
        </div>

        <br />

        <label className={classes.landingOptionsLabel}>
          <input
            type="radio"
            name="helpTasks"
            value="speak"
            checked={selectedOption === "speak"}
            onChange={handleOptionChange}
          />{" "}
          Speak with someone{" "}
        </label>

        <br />
      </div>

      <Button
        onClick={takeAction}
        color="primary"
        variant="contained"
        disabled={selectedOption !== "speak" && buttonDisabled}
      >
        GET HELP
      </Button>
    </div>
  );
}
