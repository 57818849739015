import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { Steps, useAppState } from "../../../state";
import { SendCCard, RecordVisit } from "../../../data/ClientData";
import ThankYouView from "../../Screens/ThankYouView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      maxWidth: "100px",
      //background: theme.brand,
      color: "#666",
      "&:hover": {
        background: "orange",
        color: "white"
      }
    }
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const {
    customerDCXId,
    customerPhone,
    currentStep,
    setCurrentStep
  } = useAppState();

  let params = new URLSearchParams(document.location.search);
  const clientID = params.get("ClientID") + "";
  const locationID = params.get("LocationID") + "";
  const endContact = () => {
    room!.disconnect();
    // const storedDcxClient = JSON.parse(
    //   localStorage.getItem("DCXCLIENT") as string
    // );
    RecordVisit(clientID, locationID, customerDCXId);
    SendCCard(clientID, locationID, customerDCXId, customerPhone)
      .then(() => {
        // Make it open the thank you page
        setCurrentStep(Steps.thankYouView);
        window.location.replace(
          "https://" +
            window.location.hostname +
            "/?ClientID=" +
            clientID +
            "&LocationID=" +
            locationID +
            "&ThankYou=true"
        );
      })
      .catch(error => {
        console.error("Promise rejected:", error);
      });
  };
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={endContact}
      className={clsx(classes.button, props.className)}
      data-cy-disconnect
    >
      {currentStep === Steps.videoLobby ? "End Call" : "End Chat"}
    </Button>
  );
}
