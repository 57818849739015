import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { useAppState } from '../../state';
import { ClientLogoImage } from '../../data/ClientData';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  customerLogo: {
    maxHeight: '50px'
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    }
  },
  assocTag: {
    padding: '1em',
    //border: '1px solid red',
    width: '100%',
    lineHeight: '10px',
    background: 'linear-gradient(-45deg, #ccc, #fff)',
    backgroundSize: '400% 400%',
    animation: 'gradientAnimation 10s infinite',
  },
  '@keyframes gradientAnimation': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },

}));

export default function MobileTopMenuBar() {
  const url = new URL(window.location.href);
  const urlClientID = url.searchParams.get('ClientID') as string;
  const classes = useStyles();
  const { dcxClientName, isAgent, suggestedDepartment } = useAppState();
  const isAnAgent = isAgent();
  let storedLogoURL = '';

  if (isAnAgent) {
    storedLogoURL = ClientLogoImage(urlClientID);
  } else {
    const storedClientData = JSON.parse(localStorage.getItem('DCXCLIENT') || 'null');
    storedLogoURL = storedClientData.logoURL;
  }

  return (<>
    <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>

      <Grid item xs={4} container justifyContent="center" alignItems="center"></Grid>

      <Grid item xs={4} container justifyContent="center" alignItems="center">
        <img className={classes.customerLogo} src={storedLogoURL} alt={dcxClientName} />
      </Grid>

      <Grid item xs={4} container justifyContent="center" alignItems="center">
        <EndCallButton className={classes.endCallButton} />
      </Grid>


    </Grid>
      <div className={classes.assocTag} >{suggestedDepartment} Department Specialist</div>
  </>);
}

