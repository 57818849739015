import { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  CircularProgress,
  Button
} from "@material-ui/core";
import { Steps, useAppState } from "../../state";
import { Configuration, OpenAIApi } from "openai";
import { PrismaClient } from "@prisma/client";

//const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_TOKEN });
const configuration = new Configuration({
  apiKey: "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"
});
const prisma = new PrismaClient();
const openai = new OpenAIApi(configuration);
const useStyles = makeStyles(theme => ({
  customerBgContainer: {
    marginBottom: "1em",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "160%",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "20vh",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top"
    }
  }
}));

export default function FindItemView() {
  const {
    clientID,
    locationID,
    setCurrentStep,
    findQuery,
    coverURL
  } = useAppState();
  const [aiResult, setAiresult] = useState("");
  const [aisleId, setAisleId] = useState("");
  const [loading, setLoading] = useState(false);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [departmentsLoaded, setDepartmentsLoaded] = useState(false); // State to track if departments are loaded
  const classes = useStyles();
  const goClientHome = () => setCurrentStep(Steps.landingAccountPage);

  let hostServerURL = window.location.hostname;
  hostServerURL === "localhost"
    ? (hostServerURL = "http://localhost:8081")
    : (hostServerURL = "https://" + window.location.hostname);
  const departmentsListURL = `${hostServerURL}/departments?clientId=${encodeURIComponent(
    clientID
  )}&locationId=${encodeURIComponent(locationID)}`;
  const chatGPTUrl = `${hostServerURL}/chatgpt`;
  let clientNaicsCode = "";

  useEffect(() => {
    const getDepartments = async () => {
      setLoading(true);

      try {
        const response = await fetch(departmentsListURL, { method: "GET" });
        if (response.ok) {
          let clientDepartmentsData = await response.json();
          if ((await clientDepartmentsData.data.length) <= 0) {
            console.log("No departments found for this client");
            const departmentsListURL = `${hostServerURL}/departments?clientId=9999&locationId=9999`;
            const response = await fetch(departmentsListURL, { method: "GET" });
            if (response.ok) {
              clientDepartmentsData = await response.json();
            } else {
              throw new Error("Request failed");
            }
          }
          const clientDepartmentsNames = clientDepartmentsData.data.map(
            (item: { dptName: any }) => item.dptName
          );
          setDepartmentsData(clientDepartmentsData.data);
          setDepartmentsList(clientDepartmentsNames);
          setDepartmentsLoaded(true);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getDepartments();
  }, [clientID, locationID, departmentsListURL]);

  useEffect(() => {
    if (!departmentsLoaded) {
      return;
    }

    const searchGPT = async () => {
      setLoading(true);
      let searchQuery = "";
      try {
        if (clientNaicsCode != "") {
          searchQuery = `A customer in a retail store asked the following: Where can I find a: ${findQuery}. The store only has the departments associated with this NAICS code: ${clientNaicsCode}. Which department in the store should the question be routed to? Just give me the name of the department, no other text. If the request cannot be associated with a department, please say "Sorry, we couldn't locate this item at this location."`;
        } else {
          searchQuery = `A customer in a retail store asked the following: Where can I find a: ${findQuery}. The store has the following, and only, these departments: ${departmentsList}. Which department should the question be routed to? Just give me the name of the department, no other text.`;
        }
        const { data } = await openai.createCompletion({
          model: "text-davinci-003",
          prompt: searchQuery,
          temperature: 0,
          max_tokens: 64,
          top_p: 1.0,
          frequency_penalty: 0.0,
          presence_penalty: 0.0
        });

        if (
          data.choices &&
          data.choices.length > 0 &&
          data.choices[0].text !== undefined
        ) {
          let aiChoosenDepartment = data.choices[0].text;
          aiChoosenDepartment = aiChoosenDepartment.replace(/\n\n/g, "");
          setAiresult(aiChoosenDepartment);
          departmentsData.forEach(department => {
            const departmentName = department["dptName"];
            const aisleNumber = department["aisleId"];
            if (aiChoosenDepartment === departmentName) {
              setAisleId(aisleNumber);
            }
          });
          const response = await fetch(chatGPTUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              requestText: searchQuery,
              responseText: aiChoosenDepartment
            })
          });
          const result = await response.json();
          console.log("Save result:", result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    searchGPT();
  }, [
    clientID,
    locationID,
    findQuery,
    departmentsList,
    departmentsLoaded,
    departmentsData
  ]);

  return (
    <div className="centrado">
      <div
        className={classes.customerBgContainer}
        style={{ backgroundImage: `url(${coverURL})` }}
      ></div>
      <br />
      <Typography variant="subtitle1">
        You will find <b>{findQuery}</b> in
      </Typography>
      <br />
      {loading && <CircularProgress />}
      <Typography align="center" variant="h5">
        {aiResult}
      </Typography>
      <br />
      {!loading && aisleId && (
        <Typography variant="h6">Aisle {aisleId}</Typography>
      )}
      <br />
      <Button color="primary" variant="contained" disabled={loading}>
        {loading ? "Loading..." : "See More"}
      </Button>
      <br />
      <br />
      <Button
        onClick={goClientHome}
        color="primary"
        variant="outlined"
        disabled={loading}
      >
        {loading ? "Loading..." : "New Help Request"}
      </Button>
    </div>
  );
}
