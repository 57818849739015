import { styled, Theme } from "@material-ui/core/styles";

import MenuBar from "./components/MenuBar/MenuBar";
import MobileTopMenuBar from "./components/MobileTopMenuBar/MobileTopMenuBar";
import PreJoinScreens from "./components/PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "./components/ReconnectingNotification/ReconnectingNotification";
import RecordingNotifications from "./components/RecordingNotifications/RecordingNotifications";
import Room from "./components/Room/Room";
import useHeight from "./hooks/useHeight/useHeight";
import useRoomState from "./hooks/useRoomState/useRoomState";
const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto"
});
const Main = styled("main")(({ theme }: { theme: Theme }) => ({
  overflow: "auto",
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: "#676767",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px` // Leave some space for the mobile header and footer
  }
}));

export default function App() {
  const roomState = useRoomState();
  const height = useHeight();

  return (
    <Container style={{ height }}>
      {roomState === "disconnected" ? (
        <PreJoinScreens />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}
